<template>

    <div v-if="arrayevents" class="flex">

        <div class="w-full overflow-hidden px-2 flex flex-col">

            <div class="flex-1 min-h-0 relative">

                <FullCalendar 
                    ref="fullCalendar" 
                    v-bind="options"
                    @dayRender="dayRender"
                    :key="calendarKey"
                />

                <div v-if="modalEvents" class="absolute h-full w-full top-0 left-0 bg-body bg-opacity-25 py-20 z-20 flex flex-col justify-center items-center overflow-hidden">

                    <div class="h-full w-1/2 rounded-lg bg-box shadow flex flex-col overflow-hidden">

                        <div class="h-16 w-full flex px-4 flex-row justify-start items-center bg-filters">

                            <span class="text-white text-lg font-semibold">Eventos {{dateSelected | moment('DD MMM YYYY')}}</span>
                            
                            <div class="h-10 w-10 ml-auto rounded-full hover:bg-danger hover:bg-opacity-50 flex flex-col justify-center items-center cursor-pointer" @click="modalEvents = !modalEvents">
                                <i class="mdi mdi-close text-danger text-xl"></i>
                            </div>

                        </div>

                        <div class="flex-1 min-h-0 w-full overflow-auto p-4">

                            <div v-for="(el, index) in eventsShowInModal" :key="index" class="h-20 overflow-hidden mb-4 rounded-lg border-2 cursor-pointer flex flex-col justify-center items-start px-4" :class="{'border-aux': el.organizer_group === 'ey', 'border-purple': el.organizer_group != 'ey'}" @click="eventClick(el)">
                    
                                <span :class="{'text-aux': el.organizer_group === 'ey', 'text-purple': el.organizer_group != 'ey'}">{{el.title}}</span>

                            </div>

                        </div>
                    
                    </div>


                </div>

            </div>

            <div class="mt-4 mb-2 h-10 flex flex-row justify-center items-center px-2">

                <div class="h-8 cursor-pointer w-48 px-2 rounded-lg flex flex-col justify-center items-center border-2" :class="{'bg-aux-15 border-aux':organizedby == 'ey', 'border-font-gray':organizedby != 'ey'}" @click="changeOrganized('ey')">
                    <span class="text-aux">Organizados por EY</span>
                </div>

                <div class="h-8 cursor-pointer ml-12 w-48 px-2 rounded-lg flex flex-col justify-center items-center border-2" :class="{'bg-purple-15 border-purple':organizedby == 'col', 'border-font-gray':organizedby != 'col'}" @click="changeOrganized('col')">
                    <span class="text-purple">Colaboraciones</span>
                </div>

            </div>
            
        </div>

        <div class="flex-none w-1/3 flex flex-col">
        
            <div v-if="!eventSelected" class="relative flex-none w-84 ml-auto px-2 mb-4">
                <input type="text" placeholder="Search" v-model="searchInput"
                    class="w-full h-8 rounded bg-body flex flex-row justify-start items-center px-2">
            </div>

            <div v-if="!eventSelected && arrayevents" class="flex-1 min-h-0 p-2 overflow-auto ">
                <div class="scroll-gradient"></div>

                <event 
                    v-for="(el, index) in search(arrayevents)" 
                    :key="index" 
                    :data="el" 
                    v-show="isActualDate(el.activation_date)"
                    @select="eventClick"
                    @selectEventChild="selectEventById" 
                />
            </div>

            <div v-else class="flex-1 min-h-0 p-2 overflow-auto">
                <div class="h-full rounded-lg bg-box flex flex-col p-4 relative shadow-md">
                    <router-link :to="{ name: 'EYEvents' }" class="m-1 h-6 w-6 absolute top-0 right-0 rounded-full hover:bg-purple-15
                        cursor-pointer flex flex-row justify-center items-center">
                        <i class="mdi mdi-close text-purple font-semibold cursor-pointer"></i>
                    </router-link>

                    <div v-if="eventSelected.image !== ''" class="h-40 rounded-lg boxShadow mt-3"
                        :style="'background-image: url(' + imageRoute + eventSelected.image + '); ' +
                        'background-repeat: no-repeat; background-size: cover; background-position: center'">
                    </div>

                    <div class="mt-3">
                        <span class="text-white text-xl font-medium text-left leading-normal">{{ eventSelected.title }}</span>
                    </div>

                    <div class="mt-2 py-2 px-4 rounded-lg flex flex-col justify-center relative bg-opacity-25" :class="{'bg-aux':eventSelected.organizer_group == 'ey', 'bg-purple':eventSelected.organizer_group != 'ey'}">
                        <div class="flex items-center justify-between">
                            <div class="">
                                <span class="font-medium" :class="{'text-aux':eventSelected.organizer_group == 'ey', 'text-purple':eventSelected.organizer_group != 'ey'}">
                                    {{ eventSelected.start | moment("dddd, MMMM Do YYYY") }}
                                </span>
                                <span :class="{'text-aux':eventSelected.organizer_group == 'ey', 'text-purple':eventSelected.organizer_group != 'ey'}">
                                    {{ eventSelected.start | moment('h:mm a') }}
                                </span>
                            </div>
                            <!--
                            <div class="cursor-pointer" @click="addtocalendar(eventSelected)">
                                <i class="mdi mdi-calendar-plus text-2xl" style="color: #CE7CC5; opacity: 100%"></i>
                            </div>
                            -->
                        </div>
                        
                        <div v-show="eventSelected.address !== ''" class="mt-1 flex items-center">
                            <i class="mdi mdi-map-marker text-2xl text-font-gray mr-1" :class="{'text-aux':eventSelected.organizer_group == 'ey', 'text-purple':eventSelected.organizer_group != 'ey'}"></i>
                            <span class="ml-1 text-font-gray text-base" :class="{'text-aux':eventSelected.organizer_group == 'ey', 'text-purple':eventSelected.organizer_group != 'ey'}">
                                {{ eventSelected.address }}
                            </span>
                        </div>
                    </div>

                    <div class="mt-6 flex px-2">
                        <div class="w-1/2">
                            <div v-if="eventSelected.partner_name" class="h-12">
                                <div class="pb-2">
                                    <span class="text-font-gray">
                                        Socio Responsable
                                    </span>
                                </div>
                                <div>
                                    <span class="text-font-white font-medium">
                                        {{ eventSelected.partner_name }}
                                    </span>
                                </div>
                            </div>

                            <div v-if="eventSelected.organizer" class="mt-4">
                                <div class="pb-2">
                                    <span class="text-font-gray">Organizador</span><br/>
                                </div>
                                <div>
                                    <span class="text-font-white font-medium">
                                        {{ eventSelected.organizer }}
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="w-1/2">
                            <div v-if="eventSelected.contact_name" class="h-12">
                                <a :href="`mailto:${eventSelected.contact_email}`" class="cursor-pointer">
                                    <div class="pb-2">
                                        <span class="text-font-gray">Contacto Marketing</span>
                                    </div>
                                    <div class="flex items-center">
                                        <i class="mdi mdi-email-outline text-xl" :class="{'text-aux':eventSelected.organizer_group == 'ey', 'text-purple':eventSelected.organizer_group != 'ey'}"></i>
                                        <span class="ml-3 text-font-whites font-medium">
                                        {{ eventSelected.contact_name }}
                                    </span>
                                    </div>
                                </a>
                            </div>

                            <div v-if="eventSelected.subsection" class="mt-4">
                                <div class="pb-2">
                                    <!-- <span class="text-font-gray">Subsección</span><br/> -->
                                </div>
                                <div>
                                    <span class="text-font-white font-medium">
                                        {{ eventSelected.subsection }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-auto h-10 flex flex-row justify-between items-center px-2">
                        <div v-if="eventSelected.guests && eventSelected.guests.length > 0" class="h-8 bg-font-gray-15 flex flex-row justify-start
                            items-center rounded-lg px-2 cursor-pointer"
                            @click="openFile(eventSelected.guests, 'file')">
                            <i class="mdi mdi-download text-font-gray mr-1"></i>
                            <span class="text-xss text-font-gray font-semibold mr-2">Invitados</span>
                        </div>
                        <div v-if="eventSelected.description2 && eventSelected.description2 != ''" class="h-8 bg-font-gray-15 flex flex-row justify-start
                            items-center rounded-lg px-2 cursor-pointer"
                            @click="goto(eventSelected.description2)">
                            <span class="text-xss text-font-gray font-semibold mr-2">Formulario de inscripción</span>
                        </div>
                        <div v-if="eventSelected.more_info && eventSelected.more_info.length > 0" class="h-8 bg-font-gray-15 flex flex-row justify-start items-center
                            rounded-lg px-2 cursor-pointer"
                            @click="openFile(eventSelected.more_info, 'file')">
                            <i class="mdi mdi-information-variant text-font-gray"></i>
                        </div>
                    </div>

                    <!-- <div class="h-12 rounded-lg bg-purple-15 mt-auto flex flex-row justify-center items-center
                        cursor-pointer" @click="goto(eventSelected.marker)">
                        <span class="text-purple font-semibold">Cómo llegar</span>
                    </div> -->

                </div>
            </div>

        </div>

    </div>

    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>

</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import { format } from 'highcharts';
    import {state} from "../../store";

    const event = () => import('@/components/Globals/PersonalDashboard/Events/Event');

    export default {
        components: {
            FullCalendar,
            event,
        },
        data () {
            return {
                demoEvents: {
                    events: [],
                    // color :'rgb(162, 121, 226, .15)',
                    className : 'eyevents',
                },
                calendarPlugins: [ dayGridPlugin ],
                loading: false,
                demoEventsCol: {
                    events: [],
                    className: 'colaborations',
                },
                mode: 'ey',
                arrayevents: null,
                eventSelected: null,
                searchInput: '',
                loadingEvent: false,
                organizedby: 'all',
                calendarKey: new Date().toString(),
                calendarDate: new Date,
                active: false,
                modalEvents: false,
                eventsShowInModal: false,
                dateSelected: false,
                allEvents: false
            }
        },
        methods: {
            isActualDate(date) {
                return true; // this.$moment(date).isAfter(new Date);
            },
            async load() {
                try {
                    this.loading = true;
                    const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Event&sorts[date]=DESC', {params: state.globalParams});

                    this.arrayevents = data.filter((event) => event.active == 1);;
                    this.allEvents = data
                    // await this.$nextTick();

                    // await this.selectEventById(this.eventId);

                    this.demoEvents.events = [];
                    this.demoEventsCol.events = [];

                    for (let index = 0; index < this.arrayevents.length; index++) {

                        if (this.arrayevents[index].organizer_group === 'ey') {

                            this.demoEvents.events.push(
                                {
                                    ...this.arrayevents[index],
                                    title: this.arrayevents[index].title,
                                    description: this.arrayevents[index].permissions[0] ? this.arrayevents[index].permissions[0]['name'].split(" ").pop() : '',
                                    start: this.arrayevents[index].date
                                }
                            )
                        }

                        else {

                            this.demoEventsCol.events.push(
                                {
                                    ...this.arrayevents[index],
                                    title: this.arrayevents[index].title,
                                    description: this.arrayevents[index].permissions[0] ? this.arrayevents[index].permissions[0]['name'].split(" ").pop() : '',
                                    start: this.arrayevents[index].date
                                }
                            )
                        }

                    }
                    await this.selectEventById(this.eventId);

                } catch(error) {
                } finally {
                    this.loading = false;
                }
            },
            eventClick(event) {
                this.selectEvent(event);
                if (this.eventId !== event.id) {
                    this.$router.push({ name: 'EYEvents', params: { id: event.id } });
                }
            },
            async loadEvent(id) {
                if (!id) {
                    return;
                }

                try {
                    this.loadingEvent = true;
                    const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'get?resource=Event&id=' + id, {params: {...state.globalParams, active:0}});
                    return data;
                } catch (error) {
                } finally {
                    this.loadingEvent = false;
                }
            },
            selectEvent(event) { 
                if (event) {
                    this.eventSelected = {
                        ...event,
                        start: event.date
                    };
                } else {
                    this.eventSelected = null;
                }
            },
            async selectEventById(id) {
                if (!id) {
                    this.eventSelected = null;
                    return;
                }        

                const event = await this.loadEvent(this.eventId);

                if (event && this.eventId) {
                    this.eventSelected = {
                        ...event,
                        start: event.date
                    };

                    this.goToDate(event.date);
                } else {
                    this.eventSelected = null;
                }
            },
            goToDate(date) {
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.gotoDate(date);
            },
            addtocalendar(event) {
                const text = event.title;
                // var dates = event.start + '/' + event.end
                const dates = '1577869200000/1577955600000';
                const location = event.address;
                window.open(
                    'https://calendar.google.com/calendar/r/eventedit?text=' + text + '&dates=' + dates + '&location=' + location + '&sf=true&output=xml'
                    ,'_blank')
            },
            goto(direction) { 
                window.open(direction, '_blank').focus();
            },
            search(data) {
                return this.$search(data, this.searchInput) 
            },
            openFile(file, type) {
                this.$openFile(file, type) 
            },
            dayRender({ el, date }) {
                const events = this.events.filter(event => {
                    const start = this.$moment(event.date).format('YYYY-MM-DD');
                    const yesterday = this.$moment(date).subtract({ days: 1 }).format('YYYY-MM-DD');
                    const tomorrow = this.$moment(date).add({ days: 1 }).format('YYYY-MM-DD');

                    return this.$moment(start).isBetween(yesterday, tomorrow);
                });

                if (events.length > 0) {
                    const event = events[0];
                    const fromEy = event.organizer_group === 'ey';
                    const badge = event.permissions.length > 0 ? event.permissions[0].name.split(' ')[1] : '';
                    let boolDiv = ""
                    if(events.length > 1) {
                        boolDiv = ` <div class="absolute top-0 right-0 h-5 w-5 mt-1 mr-1 rounded-full flex flex-col justify-center items-center" style="border: 1px solid ${ fromEy ? '#13F2E3' : '#A279E2' }; border-style: inset;">
                                <span style="color: ${ fromEy ? '#13F2E3' : '#A279E2' };">${events.length}</span>
                            </div>`
                    }

                    el.innerHTML = `
                        <div 
                            class="w-full h-full flex flex-col cursor-pointer border-none h-full rounded-lg z-50 relative"
                            style="border: 1px solid ${ fromEy ? '#13F2E3' : '#A279E2' }; border-style: inset;"
                        >
                           
                            ${boolDiv}
                            <div class="px-3 py-2 flex items-center">
                                <div class="leading-none">${ date.getDate() }</div>
                                <div class="ml-3 flex-1 min-w-0 flex items-center justify-between">
                                    <div class="text-xs text-font-gray leading-none">${ this.$moment(events[0].date).format('h:mm a') }</div>
                                    <div class="text-sm leading-none">${ badge }</div>
                                </div>
                            </div>

                            <div class="px-3">
                                <p 
                                    class="text-sm"
                                    style="
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;  
                                        overflow: hidden;
                                        color: ${ fromEy ? '#13F2E3' : '#A279E2' }
                                    "
                                >
                                    ${ event.title }
                                </p>
                            </div>
                        </div>
                    `;
                    
                    if(events.length == 1){

                        el.addEventListener('click', () => {
                            this.eventClick(event);
                        })

                    }
                    

                    if(events.length > 1){

                        el.addEventListener('click', () => {
                            
                            this.modalEvents = true;
                            this.eventsShowInModal = events;
                            this.dateSelected = date;
                            
                        })

                    }


                } else {
                    el.innerHTML = `
                        <div 
                            class="p-3 w-full h-full h-full rounded-lg"
                        >
                            ${ date.getDate() }
                        </div>
                    `;
                }
            },
            dayRenderEY({ el, date }) {
                const events = this.demoEvents.events.filter(event => {
                    const start = this.$moment(event.date).format('YYYY-MM-DD');
                    const yesterday = this.$moment(date).subtract({ days: 1 }).format('YYYY-MM-DD');
                    const tomorrow = this.$moment(date).add({ days: 1 }).format('YYYY-MM-DD');

                    return this.$moment(start).isBetween(yesterday, tomorrow);
                });

                if (events.length > 0) {
                    const event = events[0];
                    const fromEy = event.organizer_group === 'ey';
                    const badge = event.permissions.length > 0 ? event.permissions[0].name.split(' ')[1] : '';

                    el.innerHTML = `
                        <div 
                            class="w-full h-full flex flex-col cursor-pointer border-none h-full rounded-lg z-50"
                            style="border: 1px solid ${ fromEy ? '#13F2E3' : '#A279E2' }; border-style: inset;"
                        >
                            <div class="px-3 py-2 flex items-center">
                                <div class="leading-none">${ date.getDate() }</div>
                                <div class="ml-3 flex-1 min-w-0 flex items-center justify-between">
                                    <div class="text-xs text-font-gray leading-none">${ this.$moment(events[0].date).format('h:mm a') }</div>
                                    <div class="text-sm leading-none">${ badge }</div>
                                </div>
                            </div>

                            <div class="px-3">
                                <p 
                                    class="text-sm"
                                    style="
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;  
                                        overflow: hidden;
                                        color: ${ fromEy ? '#13F2E3' : '#A279E2' }
                                    "
                                >
                                    ${ event.title }
                                </p>
                            </div>
                        </div>
                    `;

                    el.addEventListener('click', () => {
                        this.eventClick(event);
                    })
                } else {
                    el.innerHTML = `
                        <div 
                            class="p-3 w-full h-full h-full rounded-lg"
                        >
                            ${ date.getDate() }
                        </div>
                    `;
                }
            },
            dayRenderCol({ el, date }) {
                const events = this.demoEventsCol.events.filter(event => {
                    const start = this.$moment(event.date).format('YYYY-MM-DD');
                    const yesterday = this.$moment(date).subtract({ days: 1 }).format('YYYY-MM-DD');
                    const tomorrow = this.$moment(date).add({ days: 1 }).format('YYYY-MM-DD');

                    return this.$moment(start).isBetween(yesterday, tomorrow);
                });

                if (events.length > 0) {
                    const event = events[0];
                    const fromEy = event.organizer === 'EY';
                    const badge = event.permissions.length > 0 ? event.permissions[0].name.split(' ')[1] : '';

                    el.innerHTML = `
                        <div 
                            class="w-full h-full flex flex-col cursor-pointer border-none h-full rounded-lg z-50"
                            style="border: 1px solid ${ fromEy ? '#13F2E3' : '#A279E2' }; border-style: inset;"
                        >
                            <div class="px-3 py-2 flex items-center">
                                <div class="leading-none">${ date.getDate() }</div>
                                <div class="ml-3 flex-1 min-w-0 flex items-center justify-between">
                                    <div class="text-xs text-font-gray leading-none">${ this.$moment(events[0].date).format('h:mm a') }</div>
                                    <div class="text-sm leading-none">${ badge }</div>
                                </div>
                            </div>

                            <div class="px-3">
                                <p 
                                    class="text-sm"
                                    style="
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;  
                                        overflow: hidden;
                                        color: ${ fromEy ? '#13F2E3' : '#A279E2' }
                                    "
                                >
                                    ${ event.title }
                                </p>
                            </div>
                        </div>
                    `;

                    el.addEventListener('click', () => {
                        this.eventClick(event);
                    })
                } else {
                    el.innerHTML = `
                        <div 
                            class="p-3 w-full h-full h-full rounded-lg"
                        >
                            ${ date.getDate() }
                        </div>
                    `;
                }
            },
            changeOrganized(organized){

                if(organized != this.organizedby){
                    
                    this.organizedby = organized

                } else {
                    this.organizedby = 'all'
                }

            }
        },
        computed: {
            imageRoute() { return this.$imageRoute },
            options() {
                return {
                    header: {
                        left: 'today',
                        center: 'title',
                        right: 'prev, next'
                    },
                    defaultView: 'dayGridMonth',
                    // duration: { weeks: 5 },
                    columnHeaderFormat: { weekday: 'long' },
                    eventTimeFormat: { // like '14:30:00'
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false
                    },
                    plugins: this.calendarPlugins,
                    height: 'parent',
                    eventLimit: true,
                    firstDay: 0,
                    defaultDate: this.calendarDate
                }
            },
            eyOptions() {
                return {
                    ...this.options,
                    events: this.demoEvents.events,
                };
            },
            colOptions() {
                return {
                    ...this.options,
                    events: this.demoEventsCol.events,
                };
            },
            events() {
                if (this.organizedby === 'ey') { 
                    return this.demoEvents.events;
                } else if(this.organizedby === 'col'){ 
                    return this.demoEventsCol.events;
                } else{
                    return this.arrayevents
                }
            },
            eventId() {
                return this.$route.params.id;
            }
        },
        watch: {
            async eventId(id) {
                await this.selectEventById(id);
            },
            async organizedby(){
                let calendarApi = this.$refs.fullCalendar.getApi();
                
                this.calendarDate = calendarApi.getDate();
                this.calendarKey = new Date().toString();
            },
            active(n,o){
            }
        },
        mounted() {

            this.load();

            

            setInterval(() => {
                this.load();
            }, state.refreshPersonalDashboard);
        }
    }
</script>

<style>
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';

    .fc-widget-header,
    .fc-widget-content {
        border: none !important;
    }

    .fc-widget-header table th {
        @apply pb-2;
        /* border-collapse: separate;
        border-spacing: 0 6px !important; */
    }

    .fc-widget-header table th {
        @apply text-font-dark;
    }

    .fc-content-skeleton,
    .fc-content-skeleton table {
        display: none;
    }

    .fc-content-skeleton .fc-day-number {
        padding: 6px;
        float: left !important;
    }

    .fc th {
        border: none;
    }

    .fc-week {
        margin: 0 -6px;
    }

    .fc-day-grid .fc-row {
        min-height: 0 !important;
    }

    .fc-week table {
        border-collapse: separate;
        border-spacing: 6px 2px;
        border: none;
    }

    .fc table .fc-day {
        @apply rounded-lg;
        border: none;
        background-color: #121822;
    }

    .fc-event {
        display: none;
    }
</style>